import React from "react"
import { FlagIcon, BoltIcon } from '@heroicons/react/24/outline'


export default function ({ title, positive = true }) {
    return (
        <p className={`flex items-center gap-2 `}>
            {positive
                ? <BoltIcon className="w-6 h-6 stroke-black fill-yellow-300 flex-shrink-0" />
                : <FlagIcon className="w-6 h-6 stroke-black fill-red-300 flex-shrink-0" />}
            {title}
        </p>
    )
}