module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"postCssPlugins":[null,{"content":["./src/**/*.{js,jsx,ts,tsx,vue}"],"darkMode":"class","theme":{"extend":{"colors":{"bloghalka":{"bg":"#00b6e7","beige":"#fcfdfc","main":"#121414","second":"#0a2235","accent":"#16a34a"}}}},"variants":{"extend":{"fill":["group-hover"],"stroke":["group-hover"]}},"plugins":[]}],"gatsbyRemarkPlugins":["gatsby-remark-prismjs","gatsby-remark-images"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5bfc21cf9a05e1187ecd6f9273a7f04"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
