import React from "react"

const SubHeading = ({ number, text }) => {
    return (
        <div className="flex space-x-3 text-lg border-b pb-2 mb-5 mt-10 border-gray-300">
            <span className="monoz text-bloghalka-accent">{number}</span>
            <h3 className="font-medium  text-bloghalka-main">{text}</h3>
        </div>
    )
}

export default SubHeading