import ReactECharts from 'echarts-for-react';
import React from 'react';

function Chart({ data, title, years, color }) {
    if (!data || data.length === 0) {
        return (<></>)
    }

    let option = {
        textStyle: {
            color: '#9ca3af',
            fontFamily: 'Inter, sans-serif',
        },
        xAxis: [{
            type: 'category',
            data: data.map(d => d.label),
            axisLabel: {
                fontSize: 14,
            },
            axisLine: {
                lineStyle: {
                    color: '#e5e7eb'
                }
            }
        },
        years && {
            position: "bottom",
            data: years,
            interval: 1,
            axisLine: {
                show: false
            },
            axisTick: {
                alignWithLabel: false,
                length: 45,
                align: "left",
                interval: function (index, value) {
                    return value ? true : false;
                },
                lineStyle: {
                    color: '#e5e7eb'
                }

            },
            axisLabel: {
                margin: 30,
                fontSize: 14,
            },
        }
        ],
        yAxis: {
            type: 'value',
            axisLabel: {
                fontSize: 14,
                show: false
            },
            splitLine: {
                lineStyle: {
                    color: '#e5e7eb',
                    type: "dashed"
                }
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: '#e5e7eb'
                }
            }
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '10%',
            top: '10%',
            containLabel: true
        },
        series: [
            {
                data: data.map(d => {
                    return {
                        name: d?.name,
                        value: d.value,
                        id: d?.id
                    }
                }),
                type: 'bar',
                color: color ? color : 'black',
                label: {
                    show: true,
                    color: 'black',
                    position: "top",

                },

            }
        ],
        stateAnimation: {
            duration: 2000
        }
    };


    return (
        <div className="mb-5 rounded px-5">
            {title && <span className="text-gray-400 text-sm">{title}</span>}
            <ReactECharts
                option={option}
                notMerge={true}
                lazyUpdate={true}
            />
        </div>
    );
}

export default Chart;
